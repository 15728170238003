import __request__ from 'routing/request';
interface Grantee {
    readonly id: string;
    readonly type: string;
    readonly title: string;
}
interface EntityShareResponse {
    readonly validation_result: ValidationResult;
    readonly selected_grantee_capabilities: {
        readonly [_key: string]: 'view' | 'manage' | 'own';
    };
    readonly active_shares: ActiveShare[];
    readonly missing_permissions_on_dependencies: {
        readonly [_key: string]: {
            readonly owners: {
                readonly id: string;
                readonly type: string;
                readonly title: string;
            }[];
            readonly type: string;
            readonly id: string;
            readonly title: string;
        }[];
    };
    readonly available_capabilities: AvailableCapability[];
    readonly sharing_user: string;
    readonly entity: string;
    readonly available_grantees: Grantee[];
}
interface EntityShareRequest {
    readonly selected_grantee_capabilities: {
        readonly [_key: string]: 'view' | 'manage' | 'own';
    };
}
interface PaginationParameters {
    readonly per_page: number;
    readonly query: string;
    readonly page: number;
    readonly sort_by: string;
    readonly order: string;
}
interface ActiveShare {
    readonly capability: 'view' | 'manage' | 'own';
    readonly grantee: string;
    readonly grant: string;
}
interface Object {
}
interface AvailableCapability {
    readonly id: string;
    readonly title: string;
}
interface ValidationResult {
    readonly error_context: {
        readonly [_key: string]: string[];
    };
    readonly failed: boolean;
    readonly errors: {
        readonly [_key: string]: string[];
    };
}
interface PaginatedResponse_EntityDescriptor {
    readonly [_key: string]: Object;
}
/**
 * Create / update shares for an entity or collection
 */
export function updateEntityShares(entityGRN: string, JSONBody: EntityShareRequest): Promise<unknown> {
    return __request__('POST', `/authz/shares/entities/${entityGRN}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Prepare shares for an entity or collection
 */
export function prepareShare(entityGRN: string, JSONBody: EntityShareRequest): Promise<EntityShareResponse> {
    return __request__('POST', `/authz/shares/entities/${entityGRN}/prepare`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Return shares for a user
 */
export function get(userId: string, paginationparameters?: PaginationParameters, capability?: string, entity_type?: string): Promise<PaginatedResponse_EntityDescriptor> {
    return __request__('GET', `/authz/shares/user/${userId}`, paginationparameters, { 'capability': capability, 'entity_type': entity_type }, {
        'Accept': ['application/json']
    });
}
